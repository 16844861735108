import React, {useEffect, useReducer, useState} from "react";
import {fetchData} from "../../api/useFetch";
import {AlertMessage} from "../common/AlertMessage";
import {FarmHeader} from "./header/FarmHeader";
import {Loading} from "../common/Loading";
import {FarmStructure} from "./farmStructure/FarmStructure";
import {FarmDialog} from "./right-panel/FarmDialog";
import {Drawer, makeStyles} from "@material-ui/core";
import {isTargetEqualsOrDescendantOfSpecificClassNode} from "../../services/TablesService";
import {
    getFarmVersion,
    getCellsIdFromFarmStructure, FARM_EVENT_TYPES, STATES
} from "../../services/FarmService";
import {FarmDialogCell} from "./right-panel/FarmDialogCell";
import {FarmDialogStructure} from "./right-panel/FarmDialogStructure";
import {farmMaintenanceReducer, farmMaintenancesReducerActions} from "../../services/farm/FarmMaintenancesReducer";
import {AlstomEHS} from "./AlstomEHS";
import {postFarmEvent} from "../../api/apiFarmCommand";
import {useSmartFarmData} from "../../contexts/SmartFarmDataContext";

const useStyles = makeStyles({
    paper: {
        left: "50%",
        background: "#f5f5f5"
    }
});
export const Farm = () => {

    const classes = useStyles();

    const [isFarmLoading, setIsFarmLoading] = useState(true);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [alert, setAlert] = useState();
    const [isRightPartOpen, setIsRightPartOpen] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);

    const [isWeighModalOpen, setIsWeighModalOpen] = useState(false);
    const [farmProfiles, setFarmProfiles] = useState([]);
    const [isGeneralBuzzerOn, setIsGeneralBuzzerOn] = useState(null);
    const [showDoorOpeningSoon, setShowDoorOpeningSoon] = useState(false);

    // Maintenances
    const [farmMaintenances, setFarmMaintenances] = useReducer(farmMaintenanceReducer, {});

    const {farmStructure, setFarmStructure, smartFarmData} = useSmartFarmData();

    useEffect(() => {
        setIsFarmLoading(true);
        if(selectedOrganization) {
            getFarmProfiles();
        }
    }, [selectedOrganization]);

    /* First, get Smart Farm structure */
    useEffect(() => {
        if(selectedOrganization && selectedOrganization.id) {
            fetchData('farm_structures?organization=' + selectedOrganization.id)
                .then(response => response.json())
                .catch(() => setFarmStructure(null))
                .then(data => {
                    const farmStructure = (data !== undefined && data !== null && data.length > 0) ? data[0] : null;
                    setFarmStructure(farmStructure);
                    setFarmMaintenances({type: farmMaintenancesReducerActions.SET_MAINTENANCES, value: farmStructure});
                })
                .catch(() => setFarmStructure(null))
                .finally(() => {
                    setIsFarmLoading(false);
                });
        } else {
            setIsFarmLoading(false);
        }
    }, [selectedOrganization]);

    useEffect(() => {
        if(!isRightPartOpen) {
            setSelectedCell(null);
        }
    }, [isRightPartOpen]);

    useEffect(() => {
        const handleOnline = () => {
            window.location.reload();
        };
        const handleOffline = () => {
            window.location.reload();
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);


    // Get farm profiles depending on selected organization
    const getFarmProfiles = () => {
        return fetchData('farm_profiles?organization=' + selectedOrganization.id)
            .then(response => response.json())
            .catch(() => setFarmProfiles([]))
            .then(profiles => setFarmProfiles(profiles))
            .catch(() => setFarmProfiles([]));
    }

    const handleClickOpenRightPart = (event, farmCell) => {
        if(!isTargetEqualsOrDescendantOfSpecificClassNode(event.target, 'material-scale', 1)) {
            setSelectedCell(farmCell);
            setIsRightPartOpen(true);
        }
    }

    const handleRightPartClose = () => {
        setIsRightPartOpen(false);
    }

    /* Get right panel component depending on data to display (cell panel / structure panel) */
    const getRightPanelContent = () => {
        if(selectedCell && selectedCell.id) {
            // Cell content
            return (
                <FarmDialogCell
                    farmCell={selectedCell}
                    farmProfiles={farmProfiles} getFarmProfiles={getFarmProfiles} setAlert={setAlert}
                    farmVersion={farmStructure !== null && getFarmVersion(farmStructure.version)}
                    structureMaintenance={farmMaintenances.structure} farmStructure={farmStructure}
                    cellMaintenance={farmMaintenances.cells ? farmMaintenances.cells[selectedCell.id] : null}
                />
            );
        } else if(farmStructure && farmStructure.id) {
            // General Smart Farm content
            return (
                <FarmDialogStructure
                    farmVersion={farmStructure && farmStructure.version} isGeneralBuzzerOn={isGeneralBuzzerOn}
                    structureMaintenance={farmMaintenances.structure} structureId={farmStructure.id}
                    farmStructure={farmStructure}
                />
            );
        }
    }

    /* Get system code (cell / structure) that will be used in right panel */
    const getRightPanelSystemCode = () => {
        if(selectedCell && selectedCell.id) {
            return selectedCell.system_code;
        } else if(farmStructure && farmStructure.id) {
            return farmStructure.external_system_code;
        }
        return null;
    }

    const openRightStructurePanel = () => {
        setSelectedCell(false);
        setIsRightPartOpen(true);
    }

    /* Display message in case of fan activation before door opening */
    useEffect(() => {
        if(smartFarmData === null) return;

        let showDepollutionMessage = false;

        for (const smartFarmCellData of Object.values(smartFarmData)) {
            if (smartFarmCellData.hasOwnProperty('state') && smartFarmCellData.state === STATES.PRODUCTION_BEFORE_DOOR_OPEN){
                showDepollutionMessage = true;
                break;
            }
        }

        setShowDoorOpeningSoon(showDepollutionMessage);
    }, [smartFarmData]);

    return (
        <div id="main__farm">
            {/* alert */}
            {alert ? <AlertMessage key={alert.date} message={alert.message} status={alert.status}/> : null}

            {/* Door opening after fan activation */}
            {
                showDoorOpeningSoon &&
                    <div className="modal__overlay corner" onClick={() => alert('out')}>
                        <div className="modal__dialog" onClick={(e) => e.stopPropagation()}>
                            <div className="dialog__content">
                                <h2 className="content__title">The door will open in a few seconds (depollution in progress).</h2>
                            </div>
                        </div>
                    </div>
            }

            {/* right part panel */}
            <Drawer anchor="right" open={isRightPartOpen} onClose={() => handleRightPartClose()} classes={{paper: classes.paper}}>
                {(selectedCell && selectedCell.id) || (farmStructure && farmStructure.id) ?
                    <FarmDialog
                        render={(children, parentProps) => React.Children.map(children, child =>
                            React.cloneElement(child, parentProps )
                        )}
                        isRightPartOpen={isRightPartOpen} systemCode={getRightPanelSystemCode()}
                        setAlert={setAlert} farmVersion={farmStructure !== null && getFarmVersion(farmStructure.version)}
                        isCellPanel={(selectedCell && selectedCell.id)} setFarmMaintenances={setFarmMaintenances}
                    >
                        {getRightPanelContent()}
                    </FarmDialog> :
                    <p className="no-environment-dialog">No environment selected</p>
                }
            </Drawer>

            {/* header */}
            <div className="farm-header">
                <FarmHeader
                    selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization}
                    farmVersion={(farmStructure !== null && farmStructure.version) ? farmStructure.version : null}
                    externalSystemCode={(farmStructure !== null && farmStructure.external_system_code) ? farmStructure.external_system_code : null}
                />
            </div>

            <div className="farm__zone">
                {/* smart farm */}
                {isFarmLoading ?
                    <Loading/> :
                    <>
                        {(farmStructure !== null) ?
                            <>
                                <FarmStructure
                                    farmStructure={farmStructure} handleClickOpenRightPart={handleClickOpenRightPart}
                                    setIsWeighModalOpen={setIsWeighModalOpen}
                                    openRightStructurePanel={openRightStructurePanel}
                                    isGeneralBuzzerOn={isGeneralBuzzerOn} setIsGeneralBuzzerOn={setIsGeneralBuzzerOn}
                                    farmMaintenances={farmMaintenances}
                                />
                                {
                                    process.env.REACT_APP_SHOW_EHS_DOCS === 'true' &&
                                        <AlstomEHS/>
                                }
                            </> :
                            <p>This organization is not linked to a Handddle Smart Farm.</p>
                        }
                    </>
                }
            </div>
        </div>
    )
}
