import {OutlinedCard} from "../../common/OutlinedCard";
import {Toggle} from "rsuite";
import React, {useState} from "react";
import {sendFarmCommandEvent} from "../../../../services/socketEventsService";
import {FARM_EVENT_TYPES, getCellsIdFromFarmStructure, SMART_FARM_COMMANDS} from "../../../../services/FarmService";
import {isScannerModeEnabled} from "../../../CommonFunctions";
import {DigitCode} from "../../../common/DigitCode";
import {postFarmEvent} from "../../../../api/apiFarmCommand";

export const FarmManagerBlock = (props) => {

    const {isGeneralBuzzerOn, farmStructure} = props;

    const [isOpenDoorDigitCodeVisible, setIsOpenDoorDigitCodeVisible] = useState(false);

    // TODO Manage buzzer with useMemo depending on smartFarmData
    const getIsBuzzerOn = () => {
        return isGeneralBuzzerOn > 0;
    }

    const [isScannerModeOn, setIsScannerModeOn] = useState(isScannerModeEnabled());
    const [isBuzzerOn, setIsBuzzerOn] = useState(getIsBuzzerOn());

    const handleStopBuzzer = (isOn) => {
        sendFarmCommandEvent('broadcast', SMART_FARM_COMMANDS.STOP_BUZZER, isOn ? 1 : 0);
        setIsBuzzerOn(isOn);
    };

    /* Store scanner mode activation in local storage */
    const handleScannerModeChange = (isScannerMode) => {
        if(isScannerMode) {
            localStorage.setItem('scanner_mode', 'true');
        } else {
            localStorage.setItem('scanner_mode', 'false');
        }
        setIsScannerModeOn(isScannerMode);
    }

    const handleOpenAllDoors = (user) => {
        const userId = user.id ? user.id : null;

        let systemCodes = [];

        for(const farmModule of farmStructure.farm_modules){
            for(const farmCell of farmModule.farm_cells){
                systemCodes.push(farmCell.system_code);
            }
        }

        const cellsIds = getCellsIdFromFarmStructure(farmStructure);

        for(const systemCode of systemCodes){
            if(systemCode.startsWith('T') || systemCode.startsWith('R'))
                sendFarmCommandEvent(systemCode, SMART_FARM_COMMANDS.OPEN_DOOR, 0);
            postFarmEvent(cellsIds[systemCode], FARM_EVENT_TYPES.OPEN_DOOR, 0, userId)
        }
    };

    return(
        <OutlinedCard>
            <DigitCode
              visible={isOpenDoorDigitCodeVisible} setVisible={setIsOpenDoorDigitCodeVisible}
              callback={handleOpenAllDoors} errorCallback={() => null}
            />

            <h2>General Manager</h2>

            <div className="farm__dialog_farm_manager farm__dialog_common">
                <div>
                    <div className="toggle-label-right farm-command-line scanner-toggle">
                        <Toggle checked={isScannerModeOn} onChange={isScannerMode => handleScannerModeChange(isScannerMode)}/>
                        <span className="toggle-label">Receive data from scanner</span>
                    </div>

                    <div className="toggle-label-right farm-command-line scanner-toggle">
                        <Toggle checked={isBuzzerOn} onChange={isOn => handleStopBuzzer(isOn)}/>
                        <span className="toggle-label">Buzzer sound</span>
                    </div>
                </div>

                <button type="button" onClick={() => setIsOpenDoorDigitCodeVisible(true)}>
                    Open all doors
                </button>
            </div>
        </OutlinedCard>
    );
}