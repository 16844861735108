import {
    CELL_TYPES,
    getFarmVersion,
    getSmartFarmDataGeneralMaintenance,
    SMART_FARM_DATA_FIELDS
} from "../../../services/FarmService";
import {FarmManagerBlock} from "./blocks/FarmManagerBlock";
import {OutlinedCard} from "../common/OutlinedCard";
import React from "react";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";

export const FarmDialogStructure = (props) => {

    const {getChartsDataRangeBlock, getTemperatureBlock, getHumidityBlock, farmVersion,
        isGeneralBuzzerOn, getMaintenanceBlock, structureMaintenance, structureId, farmStructure} = props;

    const isEnvironmentDataAvailable = farmVersion && getFarmVersion(farmVersion) === 2;

    const {smartFarmData} = useSmartFarmData();
    const isFarmDataGeneralMaintenance = getSmartFarmDataGeneralMaintenance(smartFarmData);

    return(
        <div className="farm-dialog-body">

            <FarmManagerBlock
                isGeneralBuzzerOn={isGeneralBuzzerOn} farmStructure={farmStructure}
            />

            <OutlinedCard>
                {isEnvironmentDataAvailable &&
                    <>
                        {getChartsDataRangeBlock()}

                        <hr/>

                        {getTemperatureBlock(false)}

                        <hr/>

                        {getHumidityBlock()}
                    </>
                }
            </OutlinedCard>

            {getMaintenanceBlock(structureMaintenance, null, structureId, null, isFarmDataGeneralMaintenance)}
        </div>
    );
}