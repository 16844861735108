import {FarmModule} from "./FarmModule";
import React, {useEffect} from "react";
import {FarmControlPanel} from "./FarmControlPanel";
import {
    isAllBuzzerOff, SMART_FARM_COMMANDS,
} from "../../../services/FarmService";
import {useSmartFarmData} from "../../../contexts/SmartFarmDataContext";

export const FarmStructure = (props) => {

    const {farmStructure, handleClickOpenRightPart, setIsWeighModalOpen, openRightStructurePanel,
        isGeneralBuzzerOn, setIsGeneralBuzzerOn, farmMaintenances} = props;

    const {smartFarmData} = useSmartFarmData();


    // TODO Manage buzzer state
    useEffect(() => {
        // Compare new smart farm data with commands in process
        const isBuzzerOn = !isAllBuzzerOff(smartFarmData);
        let generalCommandsValues = {};
        generalCommandsValues[SMART_FARM_COMMANDS.STOP_BUZZER] = isBuzzerOn
        // Update selected cell data
        // Update general buzzer value
        setIsGeneralBuzzerOn(isBuzzerOn);
    }, [smartFarmData]);

    const isModuleFirstElement = (module) => {
        return module.position === 0 && !farmStructure.is_panel_side_left;
    }

    const isModuleLastElement = (module) => {
        return module.position === (farmStructure.farm_modules.length - 1) && farmStructure.is_panel_side_left;
    }

    return(
        <div>
            <div className="farm__structure">
                {farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                        isGeneralBuzzerOn={isGeneralBuzzerOn}
                    />
                }
                {(farmStructure.farm_modules && farmStructure.farm_modules.length > 0) &&
                    farmStructure.farm_modules.map(module =>
                        <FarmModule
                            key={module.id} farmModule={module} smartFarmData={smartFarmData}
                            handleClickOpenRightPart={handleClickOpenRightPart}
                            isModuleFirstElement={isModuleFirstElement(module)} isModuleLastElement={isModuleLastElement(module)}
                            isLastModule={module.position === (farmStructure.farm_modules.length - 1)}
                            setIsWeighModalOpen={setIsWeighModalOpen}
                            farmMaintenances={farmMaintenances}
                        />
                    )
                }
                {!farmStructure.is_panel_side_left &&
                    <FarmControlPanel
                        isPanelSideLeft={farmStructure.is_panel_side_left} openRightStructurePanel={openRightStructurePanel}
                        isGeneralBuzzerOn={isGeneralBuzzerOn}
                    />
                }
            </div>
        </div>
    );
}
